import React from 'react'
import ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import App from './App'

const root = ReactDOM.createRoot(
  	document.getElementById('root') as HTMLElement
)
root.render(
	<React.StrictMode>
		<CssBaseline />
		<App />
	</React.StrictMode>
)
